<template>
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer">

                        <b-row>

                            <!-- NOTICE TYPE -->
                            <b-col
                                cols="12"
                            >
                                <b-form-group
                                    label-for="notice_type"
                                >
                                    <template #label>
                                        <strong>TIPO DE NOTICIA</strong>
                                    </template>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="tipo de noticia"
                                    >
                                        <v-select
                                            id="notice_type"
                                            placeholder="Seleccionar una opción"
                                            v-model="formData.notice_type"
                                            :options="options.noticeTypes"
                                            @input="onNoticeTypeChange"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- NOTICE TYPE: IMAGE -->
                            <template v-if="formData.notice_type && formData.notice_type.value === 1">

                                <b-col cols="12">
                                    <b-alert variant="info" :show="true">
                                        <div class="alert-body">
                                            <ul class="mb-0">
                                                <li>
                                                    Comprime y optimiza tus imágenes en 
                                                    <b-link
                                                        class="alert-link"
                                                        href="https://tinypng.com/"
                                                        target="_blank"
                                                    >
                                                        Tinify
                                                    </b-link>
                                                </li>
                                                <li>
                                                    Convierte tus imágenes a .webp en 
                                                    <b-link
                                                        class="alert-link"
                                                        href="https://pixelied.com/convert/jpg-converter"
                                                        target="_blank"
                                                    >
                                                        Pixelied
                                                    </b-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </b-alert>
                                </b-col>

                                <!-- IMAGE -->
                                <b-col md="12">

                                    <!-- PREVIEW IMG -->
                                    <div id="preview" v-if="formData.imageUrl">
                                        <b-img
                                            fluid
                                            :src="formData.imageUrl"
                                            thumbnail
                                            width="300"
                                            alt="image"
                                        />
                                    </div>

                                    <!-- IMG -->
                                    <b-form-group
                                        label-for="image"
                                    >
                                        <template #label>
                                            <strong>IMAGEN (FORMATO: webp)</strong>
                                        </template>
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="imagen"
                                        >
                                            <b-form-file
                                                id="image"
                                                v-model="formData.image"
                                                @change="onFileChange"
                                                accept=".webp"
                                                ref="image"
                                                placeholder="Elija una imagen ..."
                                                :state="errors.length > 0 ? false : null"
                                                no-drop
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                    <b-button variant="outline-primary" class="mb-2" @click="removeImage" v-if="formData.imageUrl">Eliminar imagen</b-button>
                                </b-col>

                            </template>

                            <!-- NOTICE TYPE: VIDEO -->
                            <template v-if="formData.notice_type && formData.notice_type.value === 2">

                                <!-- YOUTUBE ID -->
                                <b-col cols="12" md="6">
                                    <b-form-group
                                        label-for="youtube_id"
                                        class="mb-0"
                                    >
                                        <template #label>
                                            <strong>CÓDIGO DE YOUTUBE (https://www.youtube.com/watch?v=<span class="text-primary">bo9Z_pgByQY</span>)</strong>
                                        </template>
                                        <validation-provider
                                            #default="{ errors }"
                                            rules="required"
                                            name="código de youtube"
                                        >
                                            <b-form-input
                                                id="youtube_id"
                                                v-model="formData.youtube_id"
                                                placeholder="Ingrese el código de youtube ..."
                                                :state="errors.length > 0 ? false : null"
                                                trim
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                            </template>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="AGREGAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import noticeStoreModule from '../noticeStoreModule';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import router from '@/router';
    import store from '@/store';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required,

            vSelect
        },
        directives: {
            Ripple
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const processing = ref(false);
            const refForm = ref(null);
            const options = ref({});

            const image = ref(null);

            const formData = ref({
                notice_type: null,
                imageUrl: null,
                image: null,
                youtube_id: null
            });

            const NOTICE_APP_STORE_MODULE_NAME = 'app-notice';

			// REGISTER MODULE
			if (!store.hasModule(NOTICE_APP_STORE_MODULE_NAME)) store.registerModule(NOTICE_APP_STORE_MODULE_NAME, noticeStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(NOTICE_APP_STORE_MODULE_NAME)) store.unregisterModule(NOTICE_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const response = await axios.get('/notice-types/select');
                options.value = response.data;
            };

            const onFileChange = (e) => {
                const file = e.target.files[0];

                if (file) {
                    formData.value.image = file;
                    formData.value.imageUrl = URL.createObjectURL(file);
                } else {
                    formData.value.image = null;
                    formData.value.imageUrl = null;
                }
            };

            const onNoticeTypeChange = (e) => {
                if (!e) {

                    formData.value.notice_type = null;

                    formData.value.imageUrl = null;
                    formData.value.image = null;
                    formData.value.youtube_id = null;

                } else {

                    if (e.value === 1) { // IMAGE
                        formData.value.youtube_id = null;
                    } else { // VIDEO
                        formData.value.imageUrl = null;
                        formData.value.image = null;
                    }

                }
            };

            const resetFormData = () => {
                formData.value = {
                    notice_type: null,
                    imageUrl: null,
                    image: null,
                    youtube_id: null
                }
            };

            const removeImage = () => {
                formData.value.imageUrl = null;
                formData.value.image = null;

                image.value.reset();
            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                processing.value = true;

                const {

                    notice_type,
                    image,
                    youtube_id

                } = formData.value;

                const payload = {
                    notice_type: notice_type.value,
                    youtube_id,
                    image
                }

                store.dispatch('app-notice/createNotice', { payload })
                    .then( response => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        resetFormData();
                        refForm.value.reset();

                        setTimeout(() => {
                            router.push({ name: 'notices-edit', params: { id: response.data.id } });
                        }, 2000);

                    })
                    .catch( (err) => {
                        const message = err.response.data.message ? err.response.data.message : 'Error al agregar la noticia.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                        processing.value = false;
                    });

            };

            return {
                // DATA
                processing,
                formData,

                // REFS
                refForm,
                options,
                image,

                // METHODS
                onNoticeTypeChange,
                onFileChange,
                removeImage,
                onSubmit
            }
        }
    }

</script>

<style lang="scss" scoped>

    #preview {
        text-align: center;
        margin-bottom: 1rem;
    }

</style>